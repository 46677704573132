exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-blog-js": () => import("./../../../src/pages/company/blog.js" /* webpackChunkName: "component---src-pages-company-blog-js" */),
  "component---src-pages-company-careers-js": () => import("./../../../src/pages/company/careers.js" /* webpackChunkName: "component---src-pages-company-careers-js" */),
  "component---src-pages-features-bill-pay-js": () => import("./../../../src/pages/features/bill-pay.js" /* webpackChunkName: "component---src-pages-features-bill-pay-js" */),
  "component---src-pages-features-business-account-js": () => import("./../../../src/pages/features/business-account.js" /* webpackChunkName: "component---src-pages-features-business-account-js" */),
  "component---src-pages-features-company-cards-js": () => import("./../../../src/pages/features/company-cards.js" /* webpackChunkName: "component---src-pages-features-company-cards-js" */),
  "component---src-pages-features-reimbursements-js": () => import("./../../../src/pages/features/reimbursements.js" /* webpackChunkName: "component---src-pages-features-reimbursements-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-fake-expenses-tr-js": () => import("./../../../src/templates/fake-expenses-tr.js" /* webpackChunkName: "component---src-templates-fake-expenses-tr-js" */),
  "component---src-templates-good-bye-manual-en-js": () => import("./../../../src/templates/good-bye-manual-en.js" /* webpackChunkName: "component---src-templates-good-bye-manual-en-js" */),
  "component---src-templates-good-bye-manual-tr-js": () => import("./../../../src/templates/good-bye-manual-tr.js" /* webpackChunkName: "component---src-templates-good-bye-manual-tr-js" */)
}

