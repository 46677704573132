import wrapWithProvider from "./wrap-with-provider"

// Scss
import "./src/sass/muni.scss"

// Importing Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

import 'animate.css'

export const wrapRootElement = wrapWithProvider

